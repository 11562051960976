//global functions
export const formatCurrency = function(val) {
	// let val = (parseFloat(currency)/1).toFixed(0)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatGameType = function(val) {
	var str 
    switch(val) {
        case 1:
            str = 'Odd Even'
            break
        case 2:
            str = 'Baccarat'
            break
        case 3:
            str = 'GO STOP'
            break
        case 4:
            str = 'Nine'
            break
        default:
            str = null
            break
    }

    return str
}

export const formatGameResult = function(val,game_type) {
	var str 
    switch(val) {
        case 0:
            str = 'TIE'
            break
        case 1:
            str = game_type ==  1 ? 'ODD' :  game_type ==  2 ? 'PLAYER' :  game_type ==  3 ? 'GO' : ''
            break
        case 2:
            str = game_type ==  1 ? 'EVEN' :  game_type ==  2 ? 'BANKER' :  game_type ==  3 ? 'STOP' : ''
            break
        case null:
            str = 'CANCELLED'
            break
        default:
            str = null
            break
    }

    return str
}


// 0: 홀드 - Hold
// 1: 차단됨 - Blocked
// 2: 활성 - Active
// 3: 삭제 - Delete
export const formatDomainStatus = function(val) {
	var str 
    switch(val) {
        case 0:
            str = '홀드' 
            break
        case 1:
            str = '차단됨'
            break
        case 2:
            str = '활성'
            break
        case 3:
            str = '삭제'
            break
        default:
            str = null
            break
    }

    return str
}

// 0 : Pending
// 1 : On Hold
// 2 : Complete
// 3 : Cancelled
export const formatDepositStatus = function(val) {
	var str 
    switch(val) {
        case 0:
            str = 'Pending' 
            break
        case 1:
            str = 'On Hold'
            break
        case 2:
            str = '완료'
            break
        case 3:
            str = '취소'
            break
        default:
            str = null
            break
    }

    return str
}

// 0 = pending
// 1 = on hold
// 2 = complete
// 3 = cancelled
export const formatBannerStatus = function(val) {
	var str 
    switch(val) {
        case 0:
            str = 'Pending' 
            break
        case 1:
            str = 'On Hold'
            break
        case 2:
            str = '완료'
            break
        case 3:
            str = '취소'
            break
        default:
            str = null
            break
    }

    return str
}


export const formatPremiumStatus = function(val) {
	var str 
    switch(val) {
        case 0:
            str = 'NO' 
            break
        case 1:
            str = 'YES'
            break
        default:
            str = null
            break
    }
    return str
}