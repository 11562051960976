<template>
    <tr> 
        <td :colspan="colspan">
            <div class="pull-left" v-if="data.total">
                <ul class="pagination">
                    <li  v-for="(page,index) in data.links" :key="index" >
                        <span v-if="page.label == '...'">...</span>
                        <a v-else href="#" @click="paginate(index)" :class="`${page.active?'active-pagination':''}`">
                            {{ page.label == 'pagination.next'? t.next : page.label == 'pagination.previous'? t.prev : page.label }}
                        </a>
                    </li>
                </ul>
            </div>
            <div style="text-align: center" v-else>
                 ｎｏ　ｄａｔａ 
            </div>
        </td>
    </tr>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
   props: {
        data: { type: Object },
        colspan: { type: Number }
   },
   setup(props , { emit }) {
       const store = useStore()
       const t = computed(() => store.getters['translate/t'])

         const paginate = (index) => {
            if(index == 0) {
                if(props.data.current_page != 1) {
                    emit('paginate', props.data.current_page - 1)
                }
            }
            else if(index == props.data.links.length - 1) {
                if(props.data.current_page < props.data.last_page) {
                    emit('paginate', props.data.current_page + 1)
                }
            }
            else {
              emit('paginate', parseInt(props.data.links[index].label))
            }
		}
        return {
            t,
            paginate
        }
   }
}
</script>

<style scoped>
.disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
}

.active-pagination {
    background: #333 !important;
    color: #fff;
    font-weight: bolder;
}
</style>