<template>
  <!-- <div :class="`modal-backdrop fade ${back_drop.fade}`" @click="hideBackDrop()" :style="`display: ${back_drop.display};`"></div> -->
  <div></div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
   setup() {
        const store = useStore()
        const back_drop = computed(()=> store.state.global.back_drop)

        const hideBackDrop = () => {
        }
        return {
            back_drop,
            hideBackDrop
        }
   }

}
</script>

<style>

</style>