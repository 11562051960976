<template>
<div :class="`modal-backdrop fade ${domain_form.fade}`" :style="`display: ${domain_form.display};`"></div>
<div id="dialog-create-member" :class="`modal fade ${domain_form.fade}`" tabindex="-1" role="dialog" :style="`display: ${domain_form.display};`">
<!-- <div id="dialog-create-domain" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" style="display: none;"> -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
                <button type="button" class="close" @click="clearNewDomain()">&times;</button>
                <h1 class="modal-title">Create Domain</h1>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>E-mail</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"  v-model="domain.email"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>Domain 1</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="domain.domain1"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>Domain 2</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="domain.domain2"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>Domain 3</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="domain.domain3"/>
                        </div>
                    </div>
                    <!-- <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>Games</label>
                        </div>
                        <div class="col-sm-8">
                            <div class="create-domin-checkbox-group">
                                <div>
                                    <input type="checkbox" v-model="domain.odd_even">
                                    <span>Odd Even</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="domain.baccarat1">
                                    <span>Baccarat 1</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="domain.baccarat2">
                                    <span>Baccarat 2</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="domain.baccarat3">
                                    <span>Baccarat 3</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="domain.nine">
                                    <span>Nine</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="domain.go_stop">
                                    <span>Go Stop</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </form>
                <div class="clear"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" @click="clearNewDomain()">취소/나가기</button>
                <button class="btn btn-success pull-right" @click="createNewDomain()">만들다</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
        const store = useStore()
        //STATE
        const domain_form = computed(()=> store.state.global.domain_form)

        // INPUT
        let domain = ref({
            email: '',
            domain1: '',
            domain2: '',
            domain3: '',
        })


        // OUTPUT


        // METHODS
        const clearNewDomain = () => {
            store.dispatch('global/hideDomainForm')

            domain.email = ''
            domain.domain1 = ''
            domain.domain2 = ''
            domain.domain3 = ''
        }

        // CRUD
        const createNewDomain = () => {
            store.dispatch('global/showConfirmModal', {
                message: 'Create new domain?',
                action: {
                    to_dispatch: 'domain/create',
                    new_domain: domain.value,
                }
            })
        }

        // LIFECYCLE HOOKS

        return {
            // STATE
            domain_form,

            // INPUT
            domain,


            // OUTPUT


            //METHODS
            clearNewDomain,

            //CRUD
            createNewDomain
        }
    }
}
</script>

<style>
.create-domin-checkbox-group span {
    margin-left: 5px;
}
</style>