import { createStore, createLogger } from 'vuex'
import VueCookies from 'vue-cookies'

import currentUser from './modules/currentUser'
import domain from './modules/domain'
import banner from './modules/banner'
import dashboard from './modules/dashboard'
import global from './modules/global'
import translate from './modules/translate'
 import result from './modules/result'

export default createStore({
  // plugins: [createLogger()],
  modules: {
    currentUser,
    domain,
    banner,
    dashboard,
    global,
    translate,
    result
  }
})


