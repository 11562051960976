<template>
	<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main">
		<div class="spacer-30"></div>
		<span>[점검 안내]<br/><br/>결과값 확인 페이지 업데이트를 위해 점검중 입니다. <br/>결과문의는 이메일로 문의해주시기 바랍니다. <br/>OZLive Team.</span>
	</div>
</template>

<style scoped>
.main {
	margin: 0 !important;
}

</style>
