<template>
	<section>
        <div id="category_title_bg"></div>
        <div class="page-nav-game">
            <ul class="page-nav-category">
                <li class="game-page">OZ Baccarat <span style=" color: #969696;margin-right:5px;">Game Results</span>
                <div class="box-table-page">

                    <a href="/resultbc?table=01" v-bind:class="{'active' : table == '01'}">1번 테이블</a>
                    <a href="/resultbc?table=02" v-bind:class="{'active' : table == '02'}">2번 테이블</a>
                    <a href="/resultbc?table=03" v-bind:class="{'active' : table == '03'}">3번 테이블</a>
                </div>
                </li>
            </ul>
        </div>
        <div class="line"></div>
    </section>
    <section>
        <div class="page-nav-game container-search-result">
            <div class="search-box">
                <form id="frm-search" method="post" accept-charset="utf-8">
                    <label>
                    <input type="search" name="search" id="xsearch" class="" placeholder="검색:" v-model="search" aria-controls="example">
                    <a href="#"><img src="@/assets/image/btn-search.png" @click="rsearch()" id="btn-search-result"></a>
                    </label>
                </form>
            </div>
            <div id="showex"><span class="example-search label-color">ex) 2016-08-05 </span><span class="example-search"> 일을 검색할경우</span><b class="label-color"> 20160805 </b><span class="example-search">입력</span><br> <span class="guide">핸드폰은 가로로 보시면 편리합니다. </span></div>
        </div>
        <div class="line"></div>
    </section>
    <section>
        <table class="table-results-baccarat" cellspacing="0">
            <thead>
                <tr>
                    <th>일정</th>
                    <th>회차</th>
                    <th>P</th>
                    <th>B</th>
                    <th>결과</th>
                </tr>
            </thead>
            <tbody v-if="bc_data">
                <tr v-for="result in bc_data.data" :key="result.id">
                    <td class="sorting_1 tbl-date"><span>{{splitDate(result.game_datetime)}}</span> <span>{{splitTime(result.game_datetime)}}</span></td>
                    <td class="tbl-game-num">{{result.game_no}}</td>
                    <td>
                        <div class="card">
                            <img src="@/assets/image/player_icon.png">
                            <br>
                            <span>
                                <span>{{cardExtract(result.player_one, result.result, result.status)}}&nbsp;&nbsp;</span>
                                <span>{{cardExtract(result.player_two, result.result, result.status)}}&nbsp;&nbsp;</span>
                                <span>{{cardExtract(result.player_three, result.result, result.status)}}</span>
                            </span>
                        </div>
                    </td>
                    <td>
                        <div class="card">
                            <img src="@/assets/image/banker_icon.png">
                            <br>
                            <span>
                                <span>{{cardExtract(result.banker_one, result.result, result.status)}}&nbsp;&nbsp;</span>
                                <span>{{cardExtract(result.banker_two, result.result, result.status)}}&nbsp;&nbsp;</span>
                                <span>{{cardExtract(result.banker_three, result.result, result.status)}}</span>
                            </span>
                        </div>
                    </td>
                    <td>
                        <div v-if="result.status == 3" class="special">?</div>
                        <div v-if="result.status != 3" :class="{ 'player' : result.result == 1 , 'banker' : result.result == 2 , 'pair': result.result == 0, 'special' : result.result == null}" >{{resultLabel(result.result)}}</div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <Pagination
                    v-if="bc_data"
                    :data="bc_data"
                    @paginate="getBaccaratData"
                    :colspan="6"
                />
            </tfoot>
        </table>
    </section>
</template>
<script>
import { computed, onMounted, watchEffect , ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Pagination from '@/components/Pagination'
export default {
    components: {
        Pagination
    },
    props: {
        search: {
            type: String,
            required: true
        }
    },
    setup(props) {


        // IMPORTS

        const store = useStore()
        const route = useRouter()
        const table = ref(route.currentRoute._value.query.table);
        const bc_list = null;
        const bc_data = computed(()=> store.state.result.bc_data)
        const input = computed({
            get: () => props.search
        });
        const getBaccaratData = (page_number)=> {
            store.dispatch('result/getResultBaccarat', {page_number, 'input' : input.value})
        }

        //METHODS
        const resultLabel = (value) => {
            if(value == 1){
                return 'P';
            }
            if(value == 2){
                return 'B'
            }
            if(value == 0){
                return 'T'
            }
            else{
                return '?'
            }

        }

        const splitTime = (value) =>{
			var date = value;
			var str = date.substring(11,16);
			return str;
		}
		const splitDate = (value) =>{
			var date = value;
			var str = date.substring(0,10);
			return str;
		}

        const cardExtract = (card, rslt, status) => {
             if (status == 3) {
                return '';
            }
            if (rslt == null) {
                return '';
            }
            if ( card ) {
            var strCard = card.toString();
            var num = parseInt(strCard.substring(1));
			if ( num == 0 ) { return ''; }
			if ( isNaN(num) ) { return ''; }

			var result = '';
			if ( num == 11 ) {
				result += 'J';
			} else if ( num == 12 ) {
				result += 'Q';
			} else if ( num == 13 ) {
				result += 'K';
			} else if ( num == 14 ) {
				result += 'A';
			} else {
				result += num;
			}

			return result;
            } else {
                return '';
            }
        }

        const shapeExtract = (card, rslt) => {
            if (rslt == null) {
                return '';
            }
            if ( card ) {
            var strCard = card.toString();
            var sign = parseInt(strCard.substring(0,1));
			if ( isNaN(sign) ) { return ''; }

			if ( sign == 0 ) {
				return '';
			}

			var result = '';
			if ( sign == 1 ) {
				result += '♠';
			} else if ( sign == 2 ) {
				result += '♥';
			} else if ( sign == 3 ) {
				result += '♣';
			} else if ( sign == 4 ) {
				result += '◆';
			}
			return result;
            } else {
                return '';
            }
        }

        const changeRouteQuery = (queryVal) => {
            route.push({ path: '/web/stbc.html', query: { table: queryVal } })
            table.value = queryVal;
            getBaccaratData()
        }

        onMounted(()=> {
            getBaccaratData()
        })

        function rsearch (){
            getBaccaratData()

        }


        return  {
            // DATA
            bc_data,
            bc_list,
            splitTime,
            splitDate,
            cardExtract,
            resultLabel,
            shapeExtract,
            changeRouteQuery,
            table,
            getBaccaratData,
            rsearch,
            input
        }
    }
}
</script>

<style>
.pagination
{
    background-color: #dfdfdf;

}
.pagination li
{
    display: inline-block;
    /* float: left; */
    /* padding: 5px 10px; */
    background-color: #dfdfdf;
    padding: 5px 10px;
}
.pagination li a
{
    color: black;
}
.table-results-oddeven > tfoot > tr {
    border-bottom: 1px solid #fff;
}
</style>

