<template>
	<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" v-if="dashboard_data">
		<div class="spacer-30"></div>
		<DashboardForm 
			v-if="dashboard_data.oddeven"
			:data="dashboard_data.oddeven"
		/>
		<DashboardForm 
			v-if="dashboard_data.baccarat1"
			:data="dashboard_data.baccarat1"
		/>
		<DashboardForm 
			v-if="dashboard_data.baccarat2"
			:data="dashboard_data.baccarat2"
		/>
		<DashboardForm 
			v-if="dashboard_data.baccarat3"
			:data="dashboard_data.baccarat3"
		/>
		<DashboardForm 
			v-if="dashboard_data.gostop"
			:data="dashboard_data.gostop"
		/>
	</div>
</template>

<script>
import DashboardForm from '@/components/DashboardForm'
import { formatGameType, formatCurrency } from '@/helpers/formatter'
import { onMounted, computed, ref,watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
export default {
	components: {
		DashboardForm,
	},
	setup(props) {

		
		const store = useStore();


		// DATA
		const dashboard_data = computed(()=> store.state.dashboard.data)

		// METHODS
		const getDashboardData = () => {
			store.dispatch("dashboard/getData")
		};

		const createDeposit = (deposit) => {
			// store.dispatch("dashboard/deposit")
		};

		// LIFE CYCLE HOOKS
		onMounted(() => {
			getDashboardData();
		})

		

		return {
			// DATA
			dashboard_data,
			// FILTER

			// METHODS
			formatGameType,
			formatCurrency,


			createDeposit,
		};
	},
}
</script>

<style scoped>
.main {
	margin: 0 !important;
}

</style>
