<template>
    <div v-if="data">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="deposit-info"><b>{{ data.game_type == 1 ? 'ODD EVEN': data.game_type == 2 ? 'BACCARAT': data.game_type == 3 ? 'GO STOP': '' }} {{ data.game_type == 2 ? data.table_no: ''}}</b></h4>
            </div>
            <div class="col-md-5">
                <div class="panel panel-info box">
                    <div class="panel-heading">
                        <h2>{{ data.game_type == 1 ? 'ODD EVEN': data.game_type == 2 ? 'BACCARAT': data.game_type == 3 ? 'GO STOP': '' }} {{ data.game_type == 2 ? data.table_no: ''}}</h2>
                    </div>
                    <div class="panel-body">
                        <div class="bootstrap-table">
                            <div class="fixed-table-toolbar"></div>
                            <div class="fixed-table-container">
                                <div class="fixed-table-header">
                                    <table></table>
                                </div>
                                <div class="fixed-table-body">
                                    <table data-toggle="table" class="table table-hover">
                                        <tbody>
                                            <tr>
                                                <td>{{ data.game_type == 1 ? 'ODD EVEN': data.game_type == 2 ? 'BACCARAT': data.game_type == 3 ? 'GO STOP': '' }} {{ data.game_type == 2 ? data.table_no: ''}}</td>
                                                <!-- <td>{{ data.key }}</td> -->
                                            </tr>
                                            <tr>
                                                <td>{{ t.expiration_date }}</td>
                                                <td>
                                                    <span class="expiration-date">
                                                        {{ data.due_date }}
                                                    </span>
                                                    <!-- <span style="color:green;">(+301 일)</span> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="warning-info align-center">
                                                    <div v-html="t.dashboard_msg"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="email-livemgm align-center">
                                                    <a href="#"> ozlive.api@gmail.com </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>{{ data.game_type == 1 ? t.u_oddeven: data.game_type == 2 ? t.u_baccarat: data.game_type == 3 ? t.u_gostop: '' }} {{ data.game_type == 2 ? data.table_no: ''}} </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="panel panel-primary box">
                    <div class="panel-blue panel-widget payment-detail">
                        <div class="row no-padding payment-title">
                            <div class="col-sm-3 col-lg-2 widget-left">
                                <img src="/images/dashboard/kr.png" />
                            </div>
                            <div class="col-sm-9 col-lg-7 widget-right">
                                <p class="detail-font-size">
                                    [
                                    <b class="detail-font-size">{{ t.payment_via_email }}</b>
                                    ]<br />[
                                    <b class="detail-font-size">{{ t.pls_contact }}</b>
                                    ]<br />[
                                    <b class="detail-font-size">ozlive.api@gmail.com</b> ]
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div>
                            <div class="form-group">
                                <label>{{ t.price }}</label>
                                <select class="form-control" name="amount" :id="`select-${data.game_type}${data.table_no}`" @change="selectValue($event)">
                                    <option value="1" v-if="data.price[0]"> {{ formatCurrency(data.price[0].one_month) }}  (1개월) </option>
                                    <option value="2" v-if="data.price[0]"> {{ formatCurrency(data.price[0].two_month) }}  (2개월) </option>
                                    <option value="3" v-if="data.price[0]"> {{ formatCurrency(data.price[0].three_month) }}  (3개월) </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>{{ t.name_of_depositor }}</label>
                                <input
                                    class="form-control"
                                    :placeholder="t.name_of_depositor"
                                    v-model="new_deposit.note"
                                />
                                <button class="btn btn-primary btn-submit" @click="createDeposit(data.game_type,data.table_no)">
                                    {{ t.req_ext_app }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatGameType, formatCurrency } from '@/helpers/formatter'
import { ref,computed } from 'vue'
import { useStore } from 'vuex'
export default {
    props: {
         data: { type: Object }
    },
    setup(props) {
        const store = useStore()
        const t = computed(() => store.getters['translate/t'])

        const new_deposit = ref({
            api_key_id: props.data.id,
            game_type:  props.data.game_type,
            table_no: props.data.table_no,
            amount: null,
            month_count: null,
            name_of_depositor: '',
            note: '',
        })

        const selectValue = (e) => {
        }

        const createDeposit = (game_type,table_no) => {
            let selected = $(`#select-${game_type}${table_no} option:selected`).val()

            switch(selected) {
                case "1":
                    new_deposit.value.amount = props.data.price[0].one_month
                    new_deposit.value.month_count = 1
                    break
                case "2":
                    new_deposit.value.amount = props.data.price[0].two_month
                    new_deposit.value.month_count = 2
                    break
                case "3":
                    new_deposit.value.amount = props.data.price[0].three_month
                    new_deposit.value.month_count = 3
                    break
                default:
                    return
            }

            store.dispatch('global/showConfirmModal', {
                message: 'Create new deposit?',
                action: {
                    to_dispatch: 'dashboard/createDeposit',
                    new_deposit: new_deposit.value,
                }
            })

        }

        return {
            t,
            new_deposit,
            formatGameType,
            formatCurrency,
            createDeposit ,
            selectValue
        }
    }
}
</script>

<style scoped>
.btn {
	margin-top: 25px !important;
	height: 40px;
}

h4 {
    font-size: 20px;
	margin-left: 1px;
	margin-bottom: 5px;
}

.panel-heading {
	background-color: rgb(34, 34, 34);
	color: #fff;
}

.warning-info {
	color: red;
}
</style>