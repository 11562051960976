const state = {
    loading: false,
    back_drop: {
        display: 'none',
        fade: ''
    },
    confirm_modal: {
        show: false,
        message: '1212',
        action: null,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
    feed_back_modal: {
        show: false,
        message: '1212',
        action: null,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
    member_form: {
        action: '', //create or update
        info: {
            id: 0,
            emai_address: '',
            password: '',
            odd_even_key: '',
            odd_even_payment_date: '',
            odd_even_notice: '',
            baccarat1_key: '',
            baccarat1_payment_date: '',
            baccarat1_notice: '',
            baccarat2_key: '',
            baccarat2_payment_date: '',
            baccarat2_notice: '',
            baccarat3_key: '',
            baccarat3_payment_date: '',
            baccarat3_notice: '',
            go_stop_key: '',
            go_stop_payment_date: '',
            go_stop_notice: '',
            admin_notice: ''
        },
        show: false,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
    domain_form: {
        show: false,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
    banner_form: {
        show: false,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
    ip_block_form: {
        show: false,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
    custom_payment_form: {
        show: false,
        display: 'none',
        fade: '',
        aria_hidden: 'true',
    },
}

const getters = {
    
}

const actions = {
    // BACK DROP
    showBackDrop({state}) {
        state.back_drop.display = 'block'
        setTimeout(()=> { state.back_drop.fade = 'in' },100)
    },
    hideBackDrop({state}) {
        state.back_drop.fade = '' 
        setTimeout(()=> {state.back_drop.display = 'none'},100) 
    },
    // CONFIRMATION
    showConfirmModal({state,commit}, modal) {
        state.confirm_modal.show = true
        state.confirm_modal.message = modal.message
        state.confirm_modal.action = modal.action

        state.confirm_modal.show = true
        state.confirm_modal.display = 'block'
        setTimeout(()=> { state.confirm_modal.fade = 'in' },100)   
    },
    hideConfirmModal({state}) {
        // state.confirm_modal.show = false
        // state.confirm_modal.message = ''
        // state.confirm_modal.action = null
        
        if(state.confirm_modal.action.to_dispatch == 'domain/delete') {
            state.confirm_modal.action.domain.target.value = 0 //revert status selection
        }
        state.confirm_modal.show = false
        state.confirm_modal.fade = '' 
        setTimeout(()=> {state.confirm_modal.display = 'none'},100)  
    },
    // FEED BACK
    showFeedBackModal({state}, modal) {
        state.feed_back_modal.show = true
        state.feed_back_modal.message = modal.message
        state.feed_back_modal.action = modal.action
        
        state.feed_back_modal.show = true
        state.feed_back_modal.display = 'block'
        setTimeout(()=> { state.feed_back_modal.fade = 'in' },100)   
    },
    hideFeedBackModal({state}) {
        state.feed_back_modal.show = false
        state.feed_back_modal.message = ''
        state.feed_back_modal.action = null

        state.feed_back_modal.show = false
        state.feed_back_modal.fade = '' 
        setTimeout(()=> {state.feed_back_modal.display = 'none'},100)  
    },
    confirmModal({state,dispatch}) {
        if(state.confirm_modal.action != null) {
            //MEMBER
            if(state.confirm_modal.action.to_dispatch == 'member/create') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.new_member)
                .then((res)=> {
                    if(res.data.status == 1) {
                        dispatch('hideMemberForm')
                        dispatch('showFeedBackModal',{
                            message: 'Created new member successfully'
                        })
                        this.dispatch('member/getData')
                    } else {
                        dispatch('showFeedBackModal',{
                            message: res.data.message
                        })
                    }
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'member/update') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.member)
                .then((res)=> {
                    if(res.data.status == 1) {
                        dispatch('hideMemberForm')
                        dispatch('showFeedBackModal',{
                            message: 'Updated member successfully'
                        })
                        this.dispatch('member/getData')
                    } else {
                        dispatch('showFeedBackModal',{
                            message: res.data.message
                        })
                    }
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'member/delete') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.member_id)
                .then((res)=> {
                    if(res.data.status == 1) {
                        dispatch('hideMemberForm')
                        dispatch('showFeedBackModal',{
                            message: 'Deleted member successfully'
                        })
                        this.dispatch('member/getData')
                    } else {
                        dispatch('showFeedBackModal',{
                            message: res.data.message
                        })
                    }
                })
            }
            //DEPOSIT
            else if(state.confirm_modal.action.to_dispatch == 'dashboard/createDeposit') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.new_deposit)
                .then((res)=> {
                    
                    if(res.status == 1) {
                        if(res.context) {
                            dispatch('showFeedBackModal',{
                                message: '[Not allowed!]' + res.context.errors.message
                            })
                        }
                        else {
                            dispatch('hideMemberForm')
                            dispatch('showFeedBackModal',{
                                message: 'Created deposit successfully'
                            })
                            this.dispatch('dashboard/getData')
                        }
                    } else {
                        dispatch('showFeedBackModal',{
                            message: '[An error occured!] Creating deposit failed.'
                        })
                    }
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'deposit/updateStatus') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.deposit)
                .then((res)=> {
                    if(res.context.status == 1) {
                        dispatch('hideMemberForm')
                        dispatch('showFeedBackModal',{
                            message: 'Updated deposit successfully'
                        })
                        this.dispatch('dashboard/getData')
                    } else {
                        dispatch('showFeedBackModal',{
                            message: '[Not allowed!]' +'  '+ res.context.errors.message
                        })
                    }
                })
            }
            //DOMAIN
            else if(state.confirm_modal.action.to_dispatch == 'domain/create') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.new_domain)
                .then((res)=> {
                    if(res.data.status == 1) {
                        dispatch('hideDomainForm')
                        dispatch('showFeedBackModal',{
                            message: 'Created new domain successfully'
                        })
                        this.dispatch('domain/getData', {page_number: page_number, key: search_key.value})
                    } else {
                        dispatch('showFeedBackModal',{
                            message: res.data.message
                        })
                    }
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'domain/delete') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.domain)
                .then((res)=> {
                    if(res.data.status == 1) {
                        dispatch('hideDomainForm')
                        dispatch('showFeedBackModal',{
                            message: 'Deleted domain successfully'
                        })
                        this.dispatch('domain/getData' ,{page_number: page_number, key: search_key.value})
                    } else {
                        dispatch('showFeedBackModal',{
                            message: res.data.message
                        })
                    }
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'domain/updateStatus') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.domain)
                .then((res)=> {
                    if(res.data.status == 1) {
                        dispatch('hideDomainForm')
                        dispatch('showFeedBackModal',{
                            message: 'Updated domain successfully'
                        })
                        this.dispatch('domain/getData' ,{page_number: page_number, key: search_key.value})
                    } else {
                        dispatch('showFeedBackModal',{
                            message: res.data.message
                        })
                    }
                })
            }

            //GAME RESULT
            else if(state.confirm_modal.action.to_dispatch == 'gameResult/cancelItem') {
                this.dispatch(state.confirm_modal.action.to_dispatch , {
                    game_id: state.confirm_modal.action.game_id,
                })
            }
            //BANNER
            else if(state.confirm_modal.action.to_dispatch == 'banner/create') {
                this.dispatch(state.confirm_modal.action.to_dispatch , state.confirm_modal.action.new_banner)
                .then((res)=> {
                    if(res.context.status == 1) {
                        dispatch('hideBannerForm')
                        dispatch('showFeedBackModal',{
                            message: 'Created new banner successfully'
                        })
                        this.dispatch('banner/getData',{page_number: 1, key: ''})
                    } else {
                        dispatch('showFeedBackModal',{
                            message: 'Creating banner failed'//res.data.message
                        })
                    }
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'banner/cancelItem') {
                this.dispatch(state.confirm_modal.action.to_dispatch , {
                    banner_id: state.confirm_modal.action.banner_id,
                })
            }
            //IP BLOCK
            else if(state.confirm_modal.action.to_dispatch == 'ipBlock/updateItem') {
                this.dispatch(state.confirm_modal.action.to_dispatch , {
                    id: state.confirm_modal.action.id,
                    block_status : state.confirm_modal.action.block_status,
                    api_block_status : state.confirm_modal.action.api_block_status,
                    force_homepage_move : state.confirm_modal.action.force_homepage_move,
                })
            }
            else if(state.confirm_modal.action.to_dispatch == 'ipBlock/deleteItem') {
                this.dispatch(state.confirm_modal.action.to_dispatch , {
                    id: state.confirm_modal.action.id,
                })
            }
            //CUSTOM PAYMENT
            else if(state.confirm_modal.action.to_dispatch == 'customPayment/deleteItem') {
                this.dispatch(state.confirm_modal.action.to_dispatch , {
                    payment_id: state.confirm_modal.action.payment_id,
                })
            }
        } 

        dispatch('hideConfirmModal')
    },
    // MEMBER CREATE/UPDATE
    showMemberForm({state,commit}, param) {
        state.member_form.action = param.action

        if(param.action == 'update') {
            state.member_form.info = param.info
        }
        state.member_form.show = true
        state.member_form.display = 'block'
        setTimeout(()=> { state.member_form.fade = 'in' },100)       
    },
    hideMemberForm({state}) {
        state.member_form.action = ''
        state.member_form.info = null
        state.member_form.show = false
        state.member_form.fade = '' 
        setTimeout(()=> {state.member_form.display = 'none'},100)   
    },

    // DOMAIN CREATE/UPDATE
    showDomainForm({state,dispatch}) {
        state.domain_form.show = true
        state.domain_form.display = 'block'
        setTimeout(()=> { state.domain_form.fade = 'in' },100)         
    },
    hideDomainForm({state}) {
        state.domain_form.show = false
        state.domain_form.fade = '' 
        setTimeout(()=> {state.domain_form.display = 'none'},100)   
    },

    // BANNER CREATE/UPDATE
    showBannerForm({state,dispatch}) {
        state.banner_form.show = true
        state.banner_form.display = 'block'
        setTimeout(()=> { state.banner_form.fade = 'in' },100)         
    },
    hideBannerForm({state}) {
        state.banner_form.show = false
        state.banner_form.fade = '' 
        setTimeout(()=> {state.banner_form.display = 'none'},100)   
    },

    // IP BLOCK CREATE/UPDATE
    showIPBlockForm({state,dispatch}) {
        state.ip_block_form.show = true
        state.ip_block_form.display = 'block'
        setTimeout(()=> { state.ip_block_form.fade = 'in' },100)         
    },
    hideIPBlockForm({state}) {
        state.ip_block_form.show = false
        state.ip_block_form.fade = '' 
        setTimeout(()=> {state.ip_block_form.display = 'none'},100)   
    },

    // CUSTOM PAYMENT CREATE/UPDATE
    showCustomPaymentForm({state,dispatch}) {
        state.custom_payment_form.show = true
        state.custom_payment_form.display = 'block'
        setTimeout(()=> { state.custom_payment_form.fade = 'in' },100)         
    },
    hideCustomPaymentForm({state}) {
        state.custom_payment_form.show = false
        state.custom_payment_form.fade = '' 
        setTimeout(()=> {state.custom_payment_form.display = 'none'},100)   
    },
}

const mutations = {
    showLoader(state) {
        state.loading = true
    },
    hideLoader(state) {
        state.loading = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}