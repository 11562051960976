<template>
  <div>
    <div class="header-search">                        
        <div class="toolbar">
            <form>
                <div class="toolbar-field col-sm-4">
                    <strong>{{ t.domain }}</strong>
                    <input type="text" class="form-control" v-model="search_key"/>
                </div>
                <div class="toolbar-field">
                    <strong>&nbsp;</strong>  
                    <button type="button" class="btn btn-success" @click.prevent="viewAll()">{{ t.view_all }}</button>
                </div>
            </form>  
        </div>
    </div>
    <div>                        
        <table class="table table-bordered table-condensed">
            <thead>
                <tr>
                    <th class="th-small align-center">No.</th>
                    <th scope="col">{{ t.domain }}</th>
                    <!-- <th scope="col">{{ t.mgm_product }}</th> -->
                    <th scope="col">{{ t.traffic_status }}</th>
                </tr>
            </thead>
            <tbody v-if="domain_data">
                <tr v-for="(item,index) in domain_data.data" :key="item.id">
                    <td class="align-center">
                        {{  (index + 1) + (( domain_data.current_page - 1 ) * domain_data.per_page)  }}
                    </td>
                    <td>
                        {{ item.name }}
                    </td>
                    <!-- <td>
                        {{ formatGameType(item.game_type) }} {{ item.game_type == 2 ?  item.table_no: ''}}
                    </td> -->
                    <td class=" align-center font-red">
                       N/A
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <Pagination
                    v-if="domain_data"
                    :data="domain_data"
                    @paginate="getDomainData"
                    :colspan="3"
                />
            </tfoot>
        </table>
    </div>
  </div>

</template>

<script>
import { formatGameType } from '@/helpers/formatter'
import Pagination from  '@/components/Pagination'
import { onMounted, computed, ref ,watch } from 'vue'
import { useStore } from 'vuex'
export default {
    components: {
        Pagination
    },
    setup() {
        const store = useStore()   

        let search_key = ref('')

        const t = computed(() => store.getters['translate/t'])

        // OUTPUT    
        const domain_data = computed(()=> store.state.domain.data)

        const getDomainData = (page_number) => {
             store.dispatch('domain/getData', {page_number: page_number, key: search_key.value})
        }

        const viewAll = () => {
            search_key.value = ''
        }

        watch(()=>search_key.value, (newVal,oldVal) => {
            getDomainData();
        })

        // LIFECYCLE HOOK
        onMounted(() => {
            getDomainData();
            
        })

        return {
            t,
            viewAll,
            search_key,
            // OUTPUT
            domain_data,

            //HELPERS
            formatGameType,

            //CRUD
            getDomainData,

        }
    }
}
</script>

<style>

</style>