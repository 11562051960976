<template>
<div :class="`modal-backdrop fade ${custom_payment_form.fade}`" :style="`display: ${custom_payment_form.display};`"></div>
<div id="dialog-create-member" :class="`modal fade ${custom_payment_form.fade}`" tabindex="-1" role="dialog" :style="`display: ${custom_payment_form.display};`">
<!-- <div id="dialog-create-domain" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" style="display: none;"> -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
                <button type="button" class="close" @click="clearNewCustomPayment()">&times;</button>
                <h1 class="modal-title">Create Custom Payment</h1>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>이메일</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="custom_payment.email" placeholder="email@sample.com"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>상품</label>
                        </div>
                        <div class="col-sm-8">
                            <select class="simpleselect form-control">
                                <option value="oe">홀짝</option>
                                <option value="bc">바카라 1</option>
                                <option value="bc">바카라 2</option>
                                <option value="bc">바카라 3</option>
                                <option value="go">52 GAME</option>
                            </select> 
                            
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>한달</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control currency-input" placeholder="9,999,999"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>두달</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control currency-input" placeholder="9,999,999"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>세달</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control currency-input" placeholder="9,999,999"/>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" @click="clearNewCustomPayment()">취소/나가기</button>
                <button class="btn btn-success pull-right" @click="createNewCustomPayment()">만들다</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
        const store = useStore()
        //STATE
        const custom_payment_form = computed(()=> store.state.global.custom_payment_form)

        // INPUT
        let custom_payment = ref({
            email: '',
        })


        // OUTPUT


        // METHODS
        const clearNewCustomPayment = () => {
            store.dispatch('global/hideCustomPaymentForm')

            custom_payment.email = ''
        }

        // CRUD
        const createNewCustomPayment = () => {
            store.dispatch('global/showConfirmModal', {
                message: 'Create new custom payment?',
                action: {
                    to_dispatch: 'customPayment/create',
                    new_custom_payment: custom_payment.value,
                }
            })
        }

        // LIFECYCLE HOOKS

        return {
            // STATE
            custom_payment_form,

            // INPUT
            custom_payment,


            // OUTPUT


            //METHODS
            clearNewCustomPayment,

            //CRUD
            createNewCustomPayment
        }
    }
}
</script>

<style scoped>
.checkbox-lg {
    transform: scale(1.7);
}

.modal-dialog {
    width: 450px;
    margin: 30px auto;
}

.currency-input {
    text-align: right;
}
</style>