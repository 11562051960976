<template>
<div :class="`modal-backdrop fade ${member_form.fade}`" :style="`display: ${member_form.display};`"></div>
<div id="dialog-create-member" :class="`modal fade ${member_form.fade}`" tabindex="-1" role="dialog" :style="`display: ${member_form.display};`">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
                <button type="button" class="close" @click="clearNewMember()">&times;</button>
                <h1 class="modal-title">{{ member_form.action == 'create' ? 'Register': 'Modify' }} Member</h1>
                
            </div>
            <div class="modal-body modal-update-member-body">
                <div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>아이디</label>   
                        </div>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" v-model="member.email_address"/>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>비밀번호</label>   
                        </div>
                        <div class="col-sm-4">
                            <input class="form-control"  v-model="member.password"/>    
                        </div>
                        <div class="col-sm-2 breather-sides-2">
                            <a href="#" class="btn btn-default" @click.prevent="generatePassword()"><i class="fa fa-question"></i></a>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>홀짝 Key</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control"  v-model="member.odd_even_key"/>    
                        </div>
                        <div class="col-sm-2 breather-sides-2">
                            <a href="#" class="btn btn-default" @click.prevent="generateKey('oe')"><i class="fa fa-question"></i></a>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>홀짝 결제일</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control"  v-model="member.odd_even_payment_date"/>    
                        </div>
                        <div class="col-sm-2 breather-0">
                            <span class="text-danger font-8 font-bold">Ex: 2017-01-31 23:59:59</span>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>홀짝 공지사항</label>   
                        </div>
                        <div class="col-sm-8 ">
                            <textarea class="width-full height-60" v-model="member.odd_even_notice"></textarea>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 키</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control"  v-model="member.baccarat1_key"/>    
                        </div>
                        <div class="col-sm-2 breather-sides-2">
                            <a href="#" class="btn btn-default" @click.prevent="generateKey('bc1')"><i class="fa fa-question"></i></a>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 결제일</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" v-model="member.baccarat1_payment_date"/>    
                        </div>
                        <div class="col-sm-2 breather-0">
                            <span class="text-danger font-8 font-bold">Ex: 2017-01-31 23:59:59</span>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 공지사항</label>   
                        </div>
                        <div class="col-sm-8">
                            <textarea class="width-full height-60" v-model="member.baccarat1_notice"></textarea>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 2 키</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" v-model="member.baccarat2_key"/>    
                        </div>
                        <div class="col-sm-2 breather-sides-2">
                            <a href="#" class="btn btn-default" @click.prevent="generateKey('bc2')"><i class="fa fa-question"></i></a>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 2 결제일</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" v-model="member.baccarat2_payment_date"/>    
                        </div>
                        <div class="col-sm-2 breather-0">
                            <span class="text-danger font-8 font-bold">Ex: 2017-01-31 23:59:59</span>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 2 공지사항</label>   
                        </div>
                        <div class="col-sm-8">
                            <textarea class="width-full height-60" v-model="member.baccarat2_notice"></textarea>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 3 키</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" v-model="member.baccarat3_key"/>    
                        </div>
                        <div class="col-sm-2 breather-sides-2">
                            <a href="#" class="btn btn-default" @click.prevent="generateKey('bc3')"><i class="fa fa-question"></i></a>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 3 결제일</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" v-model="member.baccarat3_payment_date"/>    
                        </div>
                        <div class="col-sm-2 breather-0">
                            <span class="text-danger font-8 font-bold">Ex: 2017-01-31 23:59:59</span>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>바카라 3 공지사항</label>   
                        </div>
                        <div class="col-sm-8">
                            <textarea class="width-full height-60" v-model="member.baccarat3_notice"></textarea>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>52 GAME 키</label>   
                        </div>
                        <div class="col-sm-6 ">
                            <input class="form-control"  v-model="member.go_stop_key"/>    
                        </div>
                        <div class="col-sm-2 breather-sides-2">
                            <a href="#" class="btn btn-default"  @click.prevent="generateKey('gs')"><i class="fa fa-question"></i></a>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>52 GAME 결제일</label>   
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" v-model="member.go_stop_payment_date"/>    
                        </div>
                        <div class="col-sm-2 breather-0">
                            <span class="text-danger font-8 font-bold" >Ex: 2017-01-31 23:59:59</span>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>52 GAME 공지사항</label>   
                        </div>
                        <div class="col-sm-8">
                            <textarea class="width-full height-60" v-model="member.go_stop_notice"></textarea>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>관리자 메모</label>   
                        </div>
                        <div class="col-sm-8">
                            <textarea class="width-full height-60" v-model="member.note"></textarea>    
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" @click="clearNewMember()">Cancel</button>
                <button class="btn btn-success pull-right" @click="createNewMember()" v-if="member_form.action == 'create'">Register</button>
                <button class="btn btn-danger pull-right" @click="updateMember()" v-else>Modify</button>
            </div>
        </div>
    </div>
    
</div>

</template>

<script>
import { generateRandomkey } from '@/helpers/generator'
import { ref,computed, watch } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
        const store = useStore()   
        // STATE
        const member_form = computed(()=> store.state.global.member_form)

        // INPUT
        let member = ref({
            id: 0 ,
            email_address: '',
            password: '',
            odd_even_key: '',
            odd_even_payment_date: '',
            odd_even_notice: '',
            baccarat1_key: '',
            baccarat1_payment_date: '',
            baccarat1_notice: '',
            baccarat2_key: '',
            baccarat2_payment_date: '',
            baccarat2_notice: '',
            baccarat3_key: '',
            baccarat3_payment_date: '',
            baccarat3_notice: '',
            go_stop_key: '',
            go_stop_payment_date: '',
            go_stop_notice: '',
            note: ''
        })
        // let member = ref({
        //     emai_address: '',
        //     nickname: '',
        //     password: '',
        //     note: '',
        //     bank_id: '',
        //     status: '',
        // })

        // OUTPUT    


        // METHODS
        const generateKey = (game) => {
            var key = generateRandomkey(14)
            if(game == 'oe') {
                member.value.odd_even_key = key
            }
            else if(game == 'gs') {
                member.value.go_stop_key = key
            }
            else if(game == 'bc1') {
                member.value.baccarat1_key = key
            }
            else if(game == 'bc2') {
                member.value.baccarat2_key = key
            }
            else if(game == 'bc3') {
                member.value.baccarat3_key = key
            }
        }

        const generatePassword = () => {
            member.value.password = generateRandomkey(10)
        }
  

        const clearNewMember = () => {
            store.dispatch('global/hideMemberForm')
            // member.value.email_address = ''
            // member.value.nickname = ''
            // member.value.password = ''
            // member.value.note = ''
            // member.value.bank_id = ''
            // member.value.status = ''
        }

        // CRUD
        const createNewMember = () => {
            store.dispatch('global/showConfirmModal', {
                message: 'Create new member?',
                action: {
                    to_dispatch: 'member/create',
                    new_member: member.value,
                }
            })
        }

        const updateMember = () => {
            store.dispatch('global/showConfirmModal', {
                message: 'Modify member?',
                action: {
                    to_dispatch: 'member/update',
                    member: member.value,
                }
            })
        }

        // LIFECYCLE HOOKS
        watch(() => member_form.value.action, (newVal, oldVal) => {
             if(newVal == 'update') {
                member.value.id = member_form.value.info.id
                member.value.email_address = member_form.value.info.email_address
                member.value.password = member_form.value.info.password_raw
                member.value.odd_even_key = member_form.value.info.oddeven != null ? member_form.value.info.oddeven.key : ''
                member.value.odd_even_payment_date =  member_form.value.info.oddeven != null ? member_form.value.info.oddeven.created_at : ''
                member.value.baccarat1_key = member_form.value.info.baccarat1 != null ? member_form.value.info.baccarat1.key : ''
                member.value.baccarat1_payment_date =  member_form.value.info.baccarat1 != null ? member_form.value.info.baccarat1.created_at : ''
                member.value.baccarat2_key = member_form.value.info.baccarat2 != null ? member_form.value.info.baccarat2.key : ''
                member.value.baccarat2_payment_date =  member_form.value.info.baccarat2 != null ? member_form.value.info.baccarat2.created_at : ''
                member.value.baccarat3_key = member_form.value.info.baccarat3 != null ? member_form.value.info.baccarat3.key : ''
                member.value.baccarat3_payment_date =  member_form.value.info.baccarat3 != null ? member_form.value.info.baccarat3.created_at : ''
                member.value.go_stop_key = member_form.value.info.gostop != null ? member_form.value.info.gostop.key : ''
                member.value.go_stop_payment_date =  member_form.value.info.gostop != null ? member_form.value.info.gostop.created_at : ''
                member.value.note =  member_form.value.info.note
             } else {
                member.value.id = 0
                member.value.email_address = ''
                member.value.password = ''
                member.value.odd_even_key = ''
                member.value.odd_even_payment_date = ''
                member.value.baccarat1_key = ''
                member.value.baccarat1_payment_date = ''
                member.value.baccarat2_key = ''
                member.value.baccarat2_payment_date = ''
                member.value.baccarat3_key = ''
                member.value.baccarat3_payment_date = ''
                member.value.go_stop_key = ''
                member.value.go_stop_payment_date = ''
                member.value.note = ''
             }
        })

        return {
            // STATE
            member_form,
            // INPUT
            member,
            

            // OUTPUT


            //METHODS
            generateKey,
            generatePassword,
            clearNewMember,

            //CRUD
            createNewMember,
            updateMember
        }
    }
}
</script>

<style scoped>
/* .modal-update-member-body { */
    /* position: relative;
    padding: 20px; */
    /* height: 300px; */
    /* overflow-y: scroll; */
/* } */
</style>