

const state = {
    lang: 'kr', // kr & en,
    en: {
        payment_via_email: 'Payment account via e-mail',
        pls_contact: 'Please contact us.',
        navigation: 'Navigation',
        member: 'Member',
        full_member_info: 'Full Member Information',
        member_info: 'Membership Information',
        banner: 'Banner',
        domain_mngt: 'Domain Managment',
        expire: 'Expire',
        due_date: 'Due Date',
        deposit_approval: 'Deposit Approval',
        domain: 'Domain',
        iframe: 'iFrame',
        connection: 'Connection',
        monitoring: 'Monitoring',
        api: 'API',
        game_result_details: 'Game Result Details',
        odd_even: 'Odd Even',
        baccarat: 'Baccarat',
        baccarat1: 'Baccarat 1',
        baccarat2: 'Baccarat 2',
        baccarat3: 'Baccarat 3',
        go_stop: 'Go Stop',
        banner_list: 'Banner List',
        ip_block: 'IP Block',
        custom_payment: 'Custom Payment',
        daily_total: 'Daily Total',
        email_key: 'Email/Key',
        search: 'Search',
        view_all: 'View All',
        member_reg: 'Member Registration',
        email_id: 'Email/ID',
        ip_domain: 'IP/Domain',
        oe_reg_date: 'Odd Even Registration Date',
        bc1_reg_date: 'Baccarat 1 Registration Date',
        bc2_reg_date: 'Baccarat 2 Registration Date',
        bc3_reg_date: 'Baccarat 3 Registration Date',
        gs_reg_date: 'Go Stop Registration Date',
        remark: 'Remark',
        delete: 'Delete',
        expiration_date: 'Expiration date',
        price: 'Price',
        name_of_depositor: 'Name of depositor',
        req_ext_app: 'Request for deposit and extension approval',
        dashboard_msg: 'Service may be interrupted after 3 days.<br /> Inquiries about deposit',
        u_oddeven: 'ODD EVEN',
        u_baccarat: 'BACCARAT',
        u_gostop: 'GO STOP',
        information: 'Information',
        logout: 'Logout',
        site_name: 'Site Name',
        ref_code: 'Referral code',
        next: 'Next',
        prev: 'Prev',
        mgm_product: 'MGM Products',
        traffic_status: 'Traffic status today (page view)',
        create_banner: 'Create banner',
        code: 'Code',
        offer: 'Offer',
        contact: 'Contact',
        image_url: 'Image URL',
        games: 'Games',
        premium: 'Premium',
        create: 'Create',
        cancel_exit: 'Cancel/Exit',
        monitor: 'Monitoring',
        result: 'Result'
    },
    kr: {
        payment_via_email: '결제계좌는 이메일로',
        pls_contact: '문의 주시기 바랍니다',
        navigation: '네비게이션',
        member: '회원정보',
        member_info: '멤버쉽 정보',
        banner: '배너',
        domain_mngt: '도매인 관리',
        full_member_info: '전체 구성원 정보',
        expire: '만료',
        due_date : '마감일이',
        deposit_approval: '입금승인',
        domain: '도매인',
        iframe: '아이프레임',
        connection: '연결',
        monitoring: '모니터링',
        api: 'API',
        game_result_details: '게임 결과 세부 정보',
        odd_even: '홀짝',
        baccarat: '바카라',
        baccarat1: '바카라 1',
        baccarat2: '바카라 2',
        baccarat3: '바카라 3',
        go_stop: '고스톱',
        banner_list: '배너 목록',
        ip_block: 'IP 차단',
        custom_payment: '커스텀 결제',
        daily_total: '일별 합계',
        email_key: '이메일/키',
        search: '검색',
        view_all: '전체보기',
        member_reg: '회원 등록',
        email_id: '이메일/아이디',
        ip_domain: 'IP/Domain',
        oe_reg_date: '홀짝 등록일',
        bc1_reg_date: '바카라 1 등록일',
        bc2_reg_date: '바카라 2 등록일',
        bc3_reg_date: '바카라 3 등록일',
        gs_reg_date: '고스톱 등록일',
        remark: '비고',
        delete: '삭제',
        expiration_date: '만료일',
        price: '금액',
        name_of_depositor: '입금자명',
        req_ext_app: ' 입금,연장 승인요청',
        dashboard_msg: '3일 경과시 서비스가 중단될수 있습니다.<br /> 입금 관련문의',
        u_oddeven: '홀짝',
        u_baccarat: '바카라',
        u_gostop: '고스톱',
        information: '정보',
        logout: '로그아웃',
        site_name: '사이트명',
        ref_code: '추천코드',
        next: '다음',
        prev: '이전',
        mgm_product: 'MGM 상품',
        traffic_status: '금일 트래픽 현황(페이지뷰)',
        create_banner: '배너 만들기',
        code: '코드',
        offer: '제공',
        contact: '연락처',
        image_url: '이미지 URL',
        games: '게임',
        premium: '프리미엄',
        create: '생성',
        cancel_exit: '취소/나가기',
        monitor: '모니터링',
        result: '결과확인'
    }
}
const getters = {
    t: (state) => {
        var _textCollection
        switch(state.lang) {
            case 'kr':
                _textCollection = state.kr
                break
            case 'en':
                _textCollection = state.en
                break
            default:
                _textCollection = null
                break
        }

        return _textCollection
    },

}
const actions = {

}
const mutations = {
    setLang(state,lang) {
        state.lang = lang
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}




