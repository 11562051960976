// import { axios } from "@/helpers/axios" // live_mode
import axios from 'axios' //dev_mode

const state = {
    data: null,
}
const getters = {

}
const actions = {
    getData({commit, state}, page_number) {
        return new Promise((resolve,reject) => {
            let page = page_number ? page_number : 1
            let url = process.env.VUE_APP_URL + '/member/deposit-information'
            let config = { headers: { 'Authorization' : 'Bearer ' + this.state.currentUser.token }}

            let form_data = new FormData()
            form_data.append('member_id', this.state.currentUser.user_id)

            this.commit('global/showLoader')
            axios.post(url,form_data,config)
            .then((res) => {
                if(res.data.status == 1
                  && res.data.context.status == 1) {
                    commit('setData', res.data.context.data)
                    resolve(res.data.context.data)
                }
                this.commit('global/hideLoader')
            }).catch((err) => {
                this.commit('global/hideLoader')
            })
        })
    },

    createDeposit({}, new_deposit) {
        return new Promise((resolve,reject) => {

            let url =  process.env.VUE_APP_URL + '/member/deposit/create'
            let config = { headers: { 'Authorization' : 'Bearer ' + this.state.currentUser.token }}
            let form_data = new FormData()

            form_data.append('api_key_id', new_deposit.api_key_id)
            form_data.append('game_type', new_deposit.game_type)
            form_data.append('table_no', new_deposit.table_no)
            form_data.append('amount', new_deposit.amount)
            form_data.append('month_count', new_deposit.month_count)

            if(new_deposit.note != '') {
                form_data.append('member_note', new_deposit.note)
            }

            form_data.append('member_id', this.state.currentUser.user_id)

            axios.post(url, form_data, config)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        })
    },
}
const mutations = {
    setData(state, data) {
        state.data = data[0]
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
