<template>
<div :class="`modal-backdrop fade ${confirm_modal.fade}`" :style="`display: ${confirm_modal.display};`"></div>
<div :class="`bootbox modal fade ${confirm_modal.fade}`" tabindex="-1" role="dialog" aria-hidden="true" :style="`display: ${confirm_modal.display};`" >
<!-- <div id="dialog-create-member" :class="`modal fade `" tabindex="-1" role="dialog" :style="`display: ${new_domain_form.display};`">     -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="bootbox-close-button close" style="margin-top: -10px;">×</button>
                <div class="bootbox-body">{{confirm_modal.message}}</div>
            </div>
            <div class="modal-footer">
                <button data-bb-handler="cancel" type="button" class="btn btn-default" @click="cancel">Cancel</button>
                <button data-bb-handler="confirm" type="button" class="btn btn-primary" @click="confirm">OK</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()


        const confirm_modal = computed(()=> store.state.global.confirm_modal)

        const confirm = () => {
            store.dispatch('global/confirmModal')
        }

        const cancel = () => {
            store.dispatch('global/hideConfirmModal')
        }
        return {
            confirm_modal,

            confirm,
            cancel
        }
    }
}
</script>

<style scoped>
.modal-backdrop {
    z-index: 5001;
}
</style>