// import { axios } from "@/helpers/axios" // live_mode
import axios from 'axios' //dev_mode

const state = {
    data: null,
}
const getters = {

}
const actions = {
    getData({commit, state}, post_data) {
        return new Promise((resolve,reject) => {
            let page = post_data.page_number ? post_data.page_number : 1
            let url = process.env.VUE_APP_URL + '/member/banner/list' + '?page=' + page
            let config = { headers: { 'Authorization' : 'Bearer ' + this.state.currentUser.token }}
            let form_data = new FormData()
            form_data.append('limit',50)
            form_data.append('member_id', this.state.currentUser.user_id)

            if(post_data.key != '') {
                form_data.append('key', post_data.key)
            }

            this.commit('global/showLoader')
            axios.post(url,form_data,config)
            .then((res) => {
                if(res.data.status == 1
                  && res.data.context.status == 1) {
                    commit('setData', res.data.context.data)
                    resolve(res.data.context.data)
                }
                this.commit('global/hideLoader')
            }).catch((err) => {
                this.commit('global/hideLoader')
            })
        })
    },

    create({}, new_banner) {
        return new Promise((resolve,reject) => {

            let url =  process.env.VUE_APP_URL + '/member/banner/create'
            let config = { headers: { 'Authorization' : 'Bearer ' + this.state.currentUser.token }}
            let form_data = new FormData()

            form_data.append('domain', new_banner.domain)
            form_data.append('site_name', new_banner.site_name)
            form_data.append('code', new_banner.code)

            if(new_banner.offer != null) {
                form_data.append('offer', new_banner.offer)
            }

            if(new_banner.contact != null) {
                form_data.append('contact', new_banner.contact)
            }

            form_data.append('image_url', new_banner.image_url)
            form_data.append('is_odd_even', new_banner.is_odd_even)
            form_data.append('is_baccarat', new_banner.is_baccarat)
            form_data.append('is_go_stop', new_banner.is_go_stop)
            form_data.append('premium', new_banner.premium)
            form_data.append('column', new_banner.column)
            form_data.append('page_odd_even', new_banner.page_odd_even)
            form_data.append('page_baccarat', new_banner.page_baccarat)
            form_data.append('page_go_stop', new_banner.page_go_stop)

            axios.post(url, form_data, config)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        })
    },
}
const mutations = {
    setData(state, data) {
        state.data = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

