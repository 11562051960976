import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Domain from '../views/Domain.vue'
import Banner from '../views/Banner.vue'
import Dashboard from '../views/Dashboard.vue'
import Monitor from '../views/Monitor.vue'
import Result from '../views/Result.vue'
import Resultoe from '../views/Resultoe.vue'
import Resultbc from '../views/Resultbc.vue'
import Resultgs from '../views/Resultgs.vue'
const routes = [
  // {
	// 	path: '/',
	// 	redirect: {
	// 		name: 'Home'
	// 	},
	// },
  // {
  //   path: '/auth',
  //   name: 'Auth',
  //   component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  //   meta: {
	// 		requiresVisitor: true
	// 	}
  // },
  {
    path: '/auth',
    name: 'Home',
    component: Home,
    meta: {
			requiresVisitor: true
		}
  },
  {
    path: '/domain',
    name: 'Domain',
    component: Domain,
		meta: {
			requiresAuth: true
		}
  },
  {
    path: '/banner',
    name: 'Banner',
    component: Banner,
		meta: {
			requiresAuth: true
		}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
		meta: {
			requiresAuth: true
		}
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Monitor,
		meta: {
			requiresAuth: true
		}
  },
  {
    path: '/resultoe',
    name: 'Result_Odd-Even',
    component: Resultoe,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/resultbc',
		name: 'Result_Baccarat',
		component: Resultbc,
		meta: {
			table: [1, 2, 3],
				requiresAuth: true
			}
	},
	{
		path: '/resultbc2',
		name: 'Result_Baccarat-02',
		component: Resultbc,
			meta: {
				requiresAuth: true
			}
	},
	{
		path: '/resultbc3',
		name: 'Result_Baccarat-03',
		component: Resultbc,
			meta: {
				requiresAuth: true
			}
	},
	{
		path: '/resultgs',
		name: 'Result_Go-Stop',
		component: Resultgs,
			meta: {
				requiresAuth: true
			}
	},
	{
		path: '/web/sthj.html',
		name: 'Result_OE',
		component: Result,
			meta: {
				requiresAuth: true
			}
	},
	{
		path: '/web/stbc.html',
		name: 'Result_BC',
		component: Result,
		meta :{
			table: [1, 2, 3],
			requiresAuth: true
		}
	},
	{
		path: '/web/stgs.html',
		name: 'Result_GS',
		component: Result,
			meta: {
				requiresAuth: true
			}
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
