<template>
<div :class="`modal-backdrop fade ${feed_back_modal.fade}`" :style="`display: ${feed_back_modal.display};`"></div>
<div :class="`bootbox modal fade ${feed_back_modal.fade}`" tabindex="-1" role="dialog" aria-hidden="true" :style="`display: ${feed_back_modal.display};`" >
<!-- <div id="dialog-create-member" :class="`modal fade `" tabindex="-1" role="dialog" :style="`display: ${new_domain_form.display};`">     -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="bootbox-close-button close" style="margin-top: -10px;">×</button>
                <div class="bootbox-body">{{feed_back_modal.message}}</div>
            </div>
            <div class="modal-footer">
                <!-- <button data-bb-handler="cancel" type="button" class="btn btn-default" @click="cancel">Cancel</button> -->
                <button data-bb-handler="confirm" type="button" class="btn btn-primary" @click="hideFeedBackModal">OK</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()


        const feed_back_modal = computed(()=> store.state.global.feed_back_modal)

        const hideFeedBackModal = () => {
            store.dispatch('global/hideFeedBackModal')
            $('#fc-id-1').focus() // focus on username input (Auth)      
        }

        return {
            feed_back_modal,

            hideFeedBackModal
        }
    }
}
</script>

<style scoped>
.modal-backdrop {
    z-index: 5001;
}
</style>