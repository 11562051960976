<template>
  <div class="spacer-40"></div>
	<div id="container-login" class="clearfix">                 
        <div id="login-box">  
       		<div class="login-box-inner clearfix">
                <header id="login-header">
                    <a href="#" id="login-logo">
                    	<h1>OZLive</h1>
                        <h4>/Member</h4>
                    </a>
                </header>
                <div class="spacer-40"></div>	
            	<form id="form-login">  
                	<div class="row">  
                        <div class="col-lg-12">	
                        	<label for="fc-id-1">Email address:</label>
                        </div>
					</div>                                          
                	<div class="row">  
                        <div class="col-lg-12">	
                    		<input class="form-control" type="text" placeholder="Enter your email address" id="fc-id-1" tabindex="1" v-model="login_info.email_address"/>
                        </div>
					</div>
                    <div class="spacer-10"></div>
                	<div class="row">  
                        <div class="col-lg-12">	
                        	<label for="fc-id-2">Password:</label>
                            <a href="#" class="underline" @click.prevent="show_password=!show_password">{{ !show_password?'Show password':'Hide password' }}</a>
                            <!-- <a href="#" id="showpassword-trigger" class="underline" @click.prevent="show_password=!show_password">{{ !show_password?'Show password':'Hide password' }}</a> -->
                        </div>
					</div>	
                	<div class="row">  
                        <div class="col-lg-12">	
                    		<input class="form-control" :type="`${!show_password?'password':'text'}`" placeholder="Enter your password" id="fc-id-2" tabindex="2" v-model="login_info.password"/>
                        </div>
					</div>	
                    <div class="spacer-10"></div>	
                    <div class="row">
                    	<div class="col-lg-12">
                            <!-- <div class="pull-left">
                                <label><input type="checkbox" tabindex="3" v-model="login_info.remember" /><span></span> Remember?</label>
                            </div> -->
                            <!-- <div class="pull-right">
                                <a href="forgot.html" class="underline">Forgot password</a>
                            </div> -->
                        </div>
                    </div>                    
                    <div class="spacer-15"></div>
                    <div class="row">
						<div class="col-lg-12">
                        	<!-- just for the demo....replace this with an form element -->
                        	<!-- <a href="#" @click="loginUser" class="btn btn-default form-control" id="fake-submit" tabindex="4">Sign in here</a> -->
                        	<a href="#" @click="loginUser" class="btn btn-default form-control" tabindex="4">Sign in here</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <footer id="login-footer">
            <!-- <strong>Copyright © 2013 creativemilk.net</strong>
            <div class="spacer-5"></div>
            <small>Lorem ipsum dolor sit amet, islum consectetur adipiscing elit. All rights reserved.</small> -->
        </footer>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: 'Auth',
    setup() {
        const store  = useStore()
        const router = useRouter()

        // INPUT
        const login_info = ref({
            email_address: '',
            password: '',
            remember: false
        });

        const show_password = ref(false)

        // METHODS
        const loginUser = () => {
             store.dispatch('currentUser/login', login_info)
             .then((success) =>{
				if(success) {
                    store.dispatch('currentUser/getProfile')
                    .then(()=>{
                        router.push({
                            name: 'Dashboard'
                        })
                    }) 
				}
                else {
                    login_info.value.email_address = ''
                    login_info.value.password = ''
                }
			})
        }
        
        return {
            // INPUT
            login_info,
            show_password,
            
            // METHODS
            loginUser,
        }
    }
}
</script>

<style>

</style>