<template>
<div :class="`modal-backdrop fade ${banner_form.fade}`" :style="`display: ${banner_form.display};`"></div>
<div id="dialog-create-member" :class="`modal fade ${banner_form.fade}`" tabindex="-1" role="dialog" :style="`display: ${banner_form.display};`">
<!-- <div id="dialog-create-domain" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" style="display: none;"> -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
                <button type="button" class="close" @click="clearNewBanner()">&times;</button>
                <h1 class="modal-title">{{ t.create_banner }}</h1>
            </div>
            <div class="modal-body">
                <div>
                    <!-- <div class="row">
                        <div class="col-sm-4">
                            <label>E-mail</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" placeholder="ex. email@sample.com" v-model="banner.email"/>
                        </div>
                    </div> -->
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.domain }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"  v-model="banner.domain"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.site_name }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control"  v-model="banner.site_name"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.code }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="banner.code"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.offer }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="banner.offer"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.contact }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="banner.contact"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.image_url }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" v-model="banner.image_url"/>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.games }}</label>
                        </div>
                        <div class="col-sm-8">
                            <div class="create-domin-checkbox-group">
                                <div>
                                    <input type="checkbox" v-model="banner.is_odd_even"/>
                                    <span>{{ t.odd_even }}</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="banner.is_baccarat"/>
                                    <span>{{ t.baccarat }}</span>
                                </div>
                                <div>
                                    <input type="checkbox" v-model="banner.is_go_stop"/>
                                    <span>{{ t.go_stop }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>{{ t.premium }}</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="checkbox" name="premium" id="premium" class="checkbox-lg" v-model="banner.premium">
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <input type="radio" name="column" id="column_one" value="1" v-model="banner.column" :disabled="!banner.premium"> 1
                            <input type="radio" name="column" id="column_two" value="2"  v-model="banner.column" :disabled="!banner.premium"> 2
                            <input type="radio" name="column" id="column_three" value="3"  v-model="banner.column" :disabled="!banner.premium"> 3
                            <input type="radio" name="column" id="column_four" value="4"  v-model="banner.column" :disabled="!banner.premium"> 4
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <input type="radio" name="page" id="page_oe"  value="1" v-model="banner.page" :disabled="!banner.premium"> Odd Even
                            <input type="radio" name="page" id="page_bc"  value="2" v-model="banner.page" :disabled="!banner.premium"> Baccarat
                            <input type="radio" name="page" id="page_gs" value="3"  v-model="banner.page" :disabled="!banner.premium"> Go Stop
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" @click="clearNewBanner()">{{ t.cancel_exit }}</button>
                <button class="btn btn-primary pull-right" @click="createNewBanner()">{{ t.create }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
        const store = useStore()
        //STATE
        const t = computed(() => store.getters['translate/t'])
        const banner_form = computed(()=> store.state.global.banner_form)

        // INPUT
        let banner = ref({
            domain: '',
            site_name: '',
            code: '',
            offer: '',
            contact: '',
            image_url: '',
            is_odd_even: false,
            is_baccarat: false,
            is_go_stop: false,
            premium: false,
            column: 0,
            page: 0
        })


        // OUTPUT

        // METHODS
        const clearNewBanner = () => {
            store.dispatch('global/hideBannerForm')
            banner.value.domain = ''
            banner.value.site_name = ''
            banner.value.code = ''
            banner.value.offer = ''
            banner.value.contact = ''
            banner.value.image_url = ''
            banner.value.is_odd_even = false
            banner.value.is_baccarat = false
            banner.value.is_go_stop = false
            banner.value.premium = false
            banner.value.column = 0
            banner.value.page = 0
        }
        const validateDomain = (domain)=> {
            if (/^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/.test(domain))
            {
                return (true)
            }
            return (false)
        }

        const validateUrl = (url)=> {
            if (/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/.test(url))
            {
                return (true)
            }
            return (false)
        }

        // CRUD
        const createNewBanner = () => {

            if(banner.value.domain.trim() == ''){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter the domain.'
                })
                return
            }

            // if(!validateDomain(banner.value.domain.trim())) {
            //     store.dispatch('global/showFeedBackModal', {
            //         message: 'Please enter a valid domain'
            //     })
            //     return
            // }

            if(banner.value.site_name.trim() == ''){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter the site name.'
                })
                return
            }

            if(banner.value.code.trim() == ''){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter the code.'
                })
                return
            }

            if(banner.value.offer.trim() == ''){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter the odder.'
                })
                return
            }

            if(banner.value.contact.trim() == ''){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter the contact.'
                })
                return
            }
         
            if(banner.value.image_url.trim() == ''){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter the image url.'
                })
                return
            }

            if(!validateUrl(banner.value.image_url.trim())){
                store.dispatch('global/showFeedBackModal', {
                    message: 'Please enter a valid image url.'
                })
                return
            }



            let new_banner = {
                domain: banner.value.domain.trim(),
                site_name: banner.value.site_name,
                code: banner.value.code,
                offer: banner.value.offer,
                contact: banner.value.contact,
                image_url: banner.value.image_url,
                is_odd_even: banner.value.is_odd_even? 1: 0,
                is_baccarat: banner.value.is_baccarat? 1: 0,
                is_go_stop: banner.value.is_go_stop? 1: 0,
                premium: banner.value.premium? 1 : 0 ,
                column: banner.value.premium? parseInt(banner.value.column) : 0,
                page_odd_even: banner.value.premium && banner.value.page == 1 ? 1 : 0 ,
                page_baccarat: banner.value.premium && banner.value.page == 2 ? 1 : 0,
                page_go_stop: banner.value.premium && banner.value.page == 3 ? 1 : 0
            }
            store.dispatch('global/showConfirmModal', {
                message: 'Create new banner?',
                action: {
                    to_dispatch: 'banner/create',
                    new_banner: new_banner,
                }
            })
        }

        // LIFECYCLE HOOKS

        return {
            t,
            // STATE
            banner_form,

            // INPUT
            banner,


            // OUTPUT


            //METHODS
            clearNewBanner,

            //CRUD
            createNewBanner
        }
    }
}
</script>

<style scoped>
.checkbox-lg {
    transform: scale(1.7);
}

.modal-dialog {
    width: 450px;
    margin: 30px auto;
}

</style>