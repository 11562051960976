<template>
<div :class="`modal-backdrop fade ${ip_block_form.fade}`" :style="`display: ${ip_block_form.display};`"></div>
<div id="dialog-block-ip" :class="`modal fade ${ip_block_form.fade}`" tabindex="-1" role="dialog" :style="`display: ${ip_block_form.display};`">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" @click="clearNewIPBlock()">&times;</button>
                <h1 class="modal-title">Create IP Block</h1>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>아이피 주소</label>   
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" placeholder="192.168.1.100"/>    
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>아이프레임 차단여부</label>   
                        </div>
                        <div class="col-sm-4">
                            <select class="simpleselect form-control">
                                <option value="0">차단</option>
                                <option value="1">사용가능</option>
                            </select> 
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>API 차단여부</label>   
                        </div>
                        <div class="col-sm-4">
                            <select class="simpleselect form-control">
                                <option value="0">차단</option>
                                <option value="1">차단</option>
                            </select>   
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>강제 홈페이지 이동</label>   
                        </div>
                        <div class="col-sm-4">
                            <select class="simpleselect form-control">
                                <option value="0">차단</option>
                                <option value="1">사용가능</option>
                            </select> 
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col-sm-4">
                            <label>홀짝 공지사항</label>   
                        </div>
                        <div class="col-sm-8">
                            <textarea class="width-full height-60"></textarea>    
                        </div>
                    </div>
                </form>
                <div class="clear"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" @click="clearNewIPBlock()">취소/나가기</button>
                <button class="btn btn-success pull-right" @click="createNewDomain()">만들다</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
        const store = useStore()
        //STATE
        const ip_block_form = computed(()=> store.state.global.ip_block_form)

        // INPUT
        let ip_block = ref({
            email: ' ricilulu01@erpman.com',
        })


        // OUTPUT


        // METHODS
        const clearNewIPBlock = () => {
            store.dispatch('global/hideIPBlockForm')

            ip_block.email = ''
        }

        // CRUD
        const createNewDomain = () => {
            store.dispatch('global/showConfirmModal', {
                message: 'Create new IP Block',
                action: {
                    to_dispatch: 'ipBlock/create',
                    new_ip_block: ip_block.value,
                }
            })
        }

        // LIFECYCLE HOOKS

        return {
            // STATE
            ip_block_form,

            // INPUT
            ip_block,


            // OUTPUT


            //METHODS
            clearNewIPBlock,

            //CRUD
            createNewDomain
        }
    }
}
</script>

<style>

</style>