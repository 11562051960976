import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

global.axios = axios

// import '@/styles/index.css'
// import '@/styles/api.css'
// import '@/styles/mobile.css'
// import '@/styles/mobile_api.css'
// import '@/styles/custom.css'
// import '@/styles/nav-showgames.css'
import '@/styles/renew.css'
//import '@/styles/media-queries.css'

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
		if(!store.getters['currentUser/authenticated']) {
			next({
				 name: 'Home'
			})
		} else {
			next()
		}
	}else if(to.matched.some(record => record.meta.requiresVisitor)) {
		if(store.getters['currentUser/authenticated']) {
			next({
				path: '/dashboard'
			})
		} else {
			next()
		}
	} else {
		// if path have no match , redirect to dashboard
		next({
			name: 'Home'
		})
	}
})

createApp(App).use(store).use(router).mount('#app')
