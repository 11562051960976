// import { axios } from "@/helpers/axios" // live_mode
import axios from 'axios' //dev_mode

const state = {
    data: null,
    current_page: 1, //sustain current page on update
}
const getters = {

}
const actions = {
    getData({commit, state}, post_data) {
        return new Promise((resolve,reject) => {

            let page = post_data.page_number ? post_data.page_number : 1
            let url = process.env.VUE_APP_URL + '/member/domain/list' + '?page=' + page
            let config = { headers: { 'Authorization' : 'Bearer ' + this.state.currentUser.token }}
            let form_data = new FormData()
            form_data.append('limit',50)
            form_data.append('member_id', this.state.currentUser.user_id)

            if(post_data.key!='') {
                form_data.append('key', post_data.key)
            }

            this.commit('global/showLoader')
            axios.post(url,form_data,config)
            .then((res) => {
                if(res.data.status == 1) {
                    commit('setData', res.data.data)
                    resolve(res.data.data)
                }
                this.commit('global/hideLoader')
            }).catch((err) => {
                this.commit('global/hideLoader')
            })
        })
    },
}
const mutations = {
    setData(state, data) {
        state.data = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

