<template>
	<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main">
		<div class="spacer-30"></div>
		<span>모니터링 페이지 준비중 입니다. OZLive Team.</span>
	</div>
</template>

<style scoped>
.main {
	margin: 0 !important;
}

</style>
