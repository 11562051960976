

// import { axios } from "@/helpers/axios"
import axios  from "axios"


const state = {
    token:  $cookies.get("token"),
    nickname: null ,
    user_id: $cookies.get("user_id")
}
const getters = {
    authenticated: (state) => {
        return state.token !== null
    },
}
const actions = {
    login({commit,getters}, login_info) {
        if(!getters.authenticated) {
            return new Promise((resolve,reject) => {
                if(login_info.value.email_address != '' && login_info.value.password != '') {

                    let url = process.env.VUE_APP_URL + '/member/login'
                    let config = { headers: {'Content-Type' : 'multipart/form-data'}}
                    let form_data = new FormData()

                    form_data.append('email_address', login_info.value.email_address)
                    form_data.append('password', login_info.value.password)

                    axios.post(url, form_data, config)
                    .then((res) => {
                        if(res.data.context.status == 1) {
                            commit('setToken', res.data.context.token)
                            resolve(true)
                        }
                        else {
                            this.dispatch('global/showFeedBackModal',{
                                message: 'Invalid user!'
                            })
                            resolve(false)
                        }
                    }).catch((err) => {
                        this.dispatch('global/showFeedBackModal',{
                            message: 'Invalid user!'
                        })
                    })
                }
            })
        }
    },
    getProfile({getters,state,commit,dispatch}) {
        if(getters.authenticated) {
            return new Promise((resolve,reject) => {
                let url = process.env.VUE_APP_URL + '/member/get-profile'
                let config = {
                    headers: {
                        'Authorization' : 'Bearer ' + state.token,
                    }
                }
                axios.get(url,config)
                .then((res) => {
                    if(res.data.context.status == 1) {
                        commit('setProfile', res.data.context.data)
                        resolve(true)
                    }
                    else {
                        dispatch('logout')
                        commit('clear')
                    }
                }).catch((err) => {
                    dispatch('logout')
                    commit('clear')
                })

            })
        }
    },
    logout({commit,getters}) {
        if(getters.authenticated) {
            return new Promise((resolve,reject) => {
                let url = process.env.VUE_APP_URL + '/member/logout'
                let config = {
                    headers: {
                        'Authorization' : 'Bearer ' + state.token,
                    }
                }
                axios.get(url,config)
                .then((res) => {
                    commit('clear')
                    resolve(true)
                }).catch((err) => {
                    commit('clear')
                    reject(err)
                })
            })
        }
    }
}
const mutations = {
    clear(state) {
        $cookies.remove("token")
        $cookies.remove("user_id")
        state.token = null
        state.nickname = null
        state.user_id = null
    },
    setToken(state, token) {
        state.token = token

        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate()+1);
        $cookies.set("token", state.token, {'expires': expireDate})

    },
    setProfile(state, profile) {
        state.nickname = profile.nickname
        state.user_id = profile.id

        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate()+1);
        $cookies.set("user_id", state.user_id, {'expires': expireDate})
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

