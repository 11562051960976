<template>
    <div>
        <div class="header-search">                        
            <div class="toolbar">
                <div>
                    <div class="toolbar-field col-sm-4">
                        <strong>{{ t.domain }}</strong>
                        <input type="text" class="form-control" v-model="search_key"/>
                    </div>
                    <div class="toolbar-field">
                        <strong>&nbsp;</strong>  
                        <button type="button" class="btn btn-success" @click.prevent="viewAll()">{{ t.view_all }}</button>
                    </div>
                    <div class="toolbar-field pull-right">
                        <strong>&nbsp;</strong>  
                        <button class="btn btn-primary"  @click.prevent="showBannerCreateForm()">{{ t.create_banner }}</button>
                    </div>
                </div>  
            </div>
        </div>
        <div>                        
            <table class="table table-bordered table-condensed">
                <thead>
                    <tr>
                        <th class="th-small align-center">No.</th>
                        <th scope="col">{{ t.domain }}</th>
                        <th scope="col">{{ t.site_name }}</th>
                        <th scope="col">{{ t.ref_code }}</th>
                        <th scope="col">OE</th>
                        <th scope="col">BC</th>
                        <th scope="col">GS</th>
                        <th scope="col" class="align-center"></th>
                    </tr>
                </thead>
                <tbody v-if="banner_data">
                    <tr v-for="(item,index) in banner_data.data" :key="item.id">
                        <td class="align-center">
                            {{  (index + 1) + (( banner_data.current_page - 1 ) * banner_data.per_page)  }}
                        </td>
                        <td>
                            {{ item.domain }}
                        </td>
                        <td>
                            {{ item.site_name }}
                        </td>
                        <td>
                            {{ item.code }}
                        </td>
                        <td>
                            {{ item.is_odd_even == 1? 'YES' : 'NO' }}
                        </td>
                        <td>
                            {{ item.is_baccarat == 1? 'YES' : 'NO' }}
                        </td>
                        <td>
                            {{ item.is_go_stop == 1? 'YES' : 'NO' }}
                        </td>
                        <td>
                            {{ formatBannerStatus(item.status) }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <Pagination
                        v-if="banner_data"
                        :data="banner_data"
                        @paginate="getBannerData"
                        :colspan="8"
                    />
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import { formatGameType, formatBannerStatus } from '@/helpers/formatter'
import Pagination from  '@/components/Pagination'
import { onMounted, computed, ref,watch } from 'vue'
import { useStore } from 'vuex'
export default {
    components: {
        Pagination
    },
    setup() {
        const store = useStore()  
        const t = computed(() => store.getters['translate/t'])

        // INPUT 
        const banner = ref({
            domain: '',
            site_name: '',
            code: '',
            is_odd_even: false,
            is_baccarat: false,
            is_go_stop: false,
        })

        let search_key= ref('')

        // OUTPUT    
        const banner_data = computed(()=> store.state.banner.data)

        const getBannerData = (page_number) => {
             store.dispatch('banner/getData', {page_number: page_number, key: search_key.value})
        }

        //METHODS
        const showBannerCreateForm = () => {
             store.dispatch('global/showBannerForm' ,  { action: 'create' })
        }

        const viewAll = () => {
            search_key.value = ''
        }

        watch(()=>search_key.value, (newVal,oldVal) => {
            getBannerData();
        })

        // LIFECYCLE HOOK
        onMounted(() => {
            getBannerData();
            
        })

        return {
            t,
            search_key,
            viewAll,
            //INPUT
            banner,
            // OUTPUT
            banner_data,

            //HELPERS
            formatGameType,
            formatBannerStatus,

            //CRUD
            getBannerData,

            showBannerCreateForm,

        }
    }
}
</script>

<style>

</style>