<template>
	<!-- router view inside content -->
	<div class="app-container">
		<div id="container" class="clearfix" v-show="authenticated">

			<!-- ********************************************
			* SIDEBAR MAIN:                            *
			*                                          *
			* the part which contains the main         *
			* navigation, logo, search and more...     *
			******************************************** -->

		<aside id="sidebar-main" class="sidebar">

			<div class="sidebar-logo">
				<a href="#" id="logo-big"><h1>OZLive</h1><h2>/Member</h2></a>
			</div><!-- End .sidebar-logo -->

			<!-- ********** -->
			<!-- NEW MODULE -->
			<!-- ********** -->

			<div class="sidebar-module">
				<div class="sidebar-profile">
					<img src="images/users/user-1.jpg" alt="" class="avatar"/>
					<!-- <span class="indicator-dot">2</span> -->
					<ul class="sidebar-profile-list">
						<li><h3>Hi, {{ currentUser.nickname }}</h3></li>
						<li><a href="#" @click="logout">{{ t.logout }}</a></li>
					</ul>
				</div><!-- End .sidebar-profile -->
			</div><!-- End .sidebar-module -->

			<div class="sidebar-line"><!-- A seperator line --></div>

			<!-- * Tabs can be removed, if so dont forget * -->
			<!-- * to remove the .tab-pane divs(wrapper). * -->

			<ul class="ext-tabs-sidebar">
				<li class="active">
					<a href="#sidebar-tab-1"><i class="fa fa-bars"></i> {{ t.navigation }}</a>
				</li>
			</ul><!-- End .ext-tabs-sidebar -->
			<div class="tab-content">
				<div id="sidebar-tab-1" class="tab-pane active clearfix">

					<!-- ********** -->
					<!-- NEW MODULE -->
					<!-- ********** -->

					<div class="sidebar-module">
						<nav class="sidebar-nav-v2">
							<ul>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>도매인 관리</a> -->
									<router-link to="/dashboard" tag="a">
										 <i class="fa fa-th"></i>{{ t.member_info }}
									</router-link>
								</li>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>도매인 관리</a> -->
									<router-link to="/banner" tag="a">
										<i class="fa fa-align-justify"></i>{{ t.banner }}
									</router-link>
								</li>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>도매인 관리</a> -->
									<router-link to="/domain" tag="a">
										<i class="fa fa-outdent"></i>{{ t.domain_mngt }}
									</router-link>
								</li>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>모니터링</a> -->
									<router-link to="/monitor" tag="a">
										<i class="fa fa-th"></i>{{ t.monitor }}
									</router-link>
								</li>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>결과 확인</a> -->
									<router-link to="/resultoe" tag="a">
										<i class="fa fa-align-justify"></i>{{ t.result }} - {{ t.odd_even }}
									</router-link>
								</li>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>결과 확인</a> -->
									<router-link to="/resultbc" tag="a">
										<i class="fa fa-align-justify"></i>{{ t.result }} - {{ t.baccarat }}
									</router-link>
								</li>
								<li>
									<!-- <a href="#"><i class="fa fa-align-justify"></i>결과 확인</a> -->
									<router-link to="/resultgs" tag="a">
										<i class="fa fa-align-justify"></i>{{ t.result }} - {{ t.go_stop }}
									</router-link>
								</li>
							</ul>
						</nav><!-- End .sidebar-nav-v1 -->
				</div><!-- End .sidebar-module -->
				</div>
				<div id="sidebar-tab-2" class="tab-pane clearfix">

					<!-- ********** -->
					<!-- NEW MODULE -->
					<!-- ********** -->
				</div>
			</div><!-- End .tab-content -->
		</aside><!-- End aside -->

		<div id="main" class="clearfix">

			<!-- ********************************************
			* MAIN HEADER:                             *
			*                                          *
			* the part which contains the breadcrumbs, *
			* dropdown menus, toggle sidebar button    *
			******************************************** -->

		<header id="header-main">
			<div class="header-main-top">
				<div class="pull-left">
					<!-- * This is the responsive logo * -->
					<a href="#" id="logo-small"><h4>karma</h4><h5>/webapp</h5></a>
				</div>
				<div class="pull-right">
					<!-- * This is the trigger that will show/hide the menu * -->
					<!-- * if the layout is in responsive mode              * -->

					<a href="#" id="responsive-menu-trigger">
						<i class="fa fa-bars"></i>
					</a>
				</div>
			</div><!-- End #header-main-top -->
			<div class="header-main-bottom">
			</div><!-- End #header-main-bottom -->

		</header><!-- End #header-main -->

		<div id="content" class="clearfix">

			<!-- ********************************************
					* HEADER SEC:                              *
					*                                          *
					* the part which contains the page title,  *
					* buttons and dropdowns.                   *
					******************************************** -->

				<header id="header-sec">
					<div class="inner-padding">
						<div class="pull-left">
							<h2>{{ $route.name }} </h2>
						</div>
						<div class="pull-right">
							<div class="lang">
								<div class="dropdown" id="lang">
									<!-- <b class="pull-left mr-10"><label style="margin-right:10px;" ng-bind="trans.language" class="ng-binding">LANG</label></b> -->
									<a class="btn btn-default dropdown-toggle ext-flags" data-toggle="dropdown" href="#">
										Language
									</a>
									<ul role="menu" class="dropdown-menu ext-flags pull-right">
										<li>
											<a href="" @click="translate('kr')">Korean <img src="/images/icons/flags/flat/16/South-Korea.png" alt=""></a>
										</li>
										<li>
											<a href="" @click="translate('en')">English <img src="/images/icons/flags/flat/16/United-Kingdom.png" alt=""></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div><!-- End .inner-padding -->
				</header><!-- End #header-sec -->

				<!-- ********************************************
						* WINDOW:                                  *
						*                                          *
						* the part which contains the main content *
						******************************************** -->

						<!-- End .window -->
				<!-- ROUTER VIEW -->
				<router-view></router-view>
				<div class="spacer-30"></div>
				<!-- ********************************************
						* FOOTER MAIN:                             *
						*                                          *
						* the part which contains things like      *
						* chat, buttons, copyright and             *
						* dropup menu(s).                          *
						******************************************** -->




		</div><!-- End #content -->

		</div><!-- End #main -->
		</div><!-- End #container -->

		<!-- <div v-else>
			<router-view></router-view>
		</div> -->

		<!-- AUTH -->
		<Auth  v-if="!authenticated"/>


		<!--Modal -->
		<div class="modal fade" id="modal-update" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
				<h4 class="modal-title">Update 13.2.1 available</h4>
				</div>
				<div class="modal-body">
						<div class="row" style="margin-bottom:-20px">
							<div class="col-xs-3">
								<i class="fa fa-download" style="font-size:120px;color:#ccc"></i>
							</div>
							<div class="col-xs-9">
								<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet molestie justo at pulvinar.
								In hac habitasse platea dictumst. Proin accumsan, tellus quis varius molestie, mi dolor facilisis risus,
								quis tristique neque augue eget nunc. Curabitur turpis sapien, lacinia in lacinia nec,
								</p>
								<div class="spacer-20"></div>
								<h4>Whats new in version 13.2.1</h4>
								<div class="spacer-20"></div>
								<ul>
									<li>Suspendisse laoreet molestie justo at pulvinar.</li>
									<li>Proin accumsan, tellus quis varius molestie, mi dolor facilisis risus.</li>
									<li>In hac habitasse platea dictumst. Proin accumsan, tellus quis varius molestie dolum ipkut. Curabitur turpis sapien lorem.</li>
									<li>Curabitur turpis sapien, lacinia in lacinia necr.</li>
									<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet molestie justo.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-default" data-dismiss="modal">Close</button>
						<button class="btn btn-primary pull-right">Get it now</button>
					</div>
			</div>
		</div>
		</div>


	<MemberForm v-if="authenticated"/>
    <DomainForm v-if="authenticated"/>
    <BannerForm v-if="authenticated"/>
    <IPBlockForm v-if="authenticated"/>
    <CustomPaymentForm v-if="authenticated"/>
    <ConfirmModal v-if="authenticated"/>
    <FeedBackModal/>
    <BackDrop v-if="authenticated"/>
    <Loader v-if="authenticated"/>

	</div>

</template>

<script>


import { computed, onUpdated, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import MemberForm from '@/components/MemberForm'
import DomainForm from '@/components/DomainForm'
import BannerForm from '@/components/BannerForm'
import IPBlockForm from '@/components/IPBlockForm'
import CustomPaymentForm from '@/components/CustomPaymentForm'
import ConfirmModal from '@/components/ConfirmModal'
import FeedBackModal from '@/components/FeedBackModal'
import BackDrop from '@/components/BackDrop'
import Loader from '@/components/Loader'
import Auth from '@/views/Auth'

export default {
	name: 'App',
	components: {
		MemberForm,
		DomainForm,
		BannerForm,
		IPBlockForm,
		CustomPaymentForm,
		ConfirmModal,
		FeedBackModal,
		BackDrop,
		Loader,
		Auth
	},
	setup() {
		const store  = useStore()
		const router = useRouter()


		//	STATE MANAGEMENT
		const t = computed(() => store.getters['translate/t'])

		const currentUser = computed(() => store.state.currentUser)
		const authenticated = computed(() => store.getters['currentUser/authenticated'])

		//  METHODS
		const logout = () => {
			store.dispatch('currentUser/logout')
			.then((success) =>{
				if(success) {
					router.push({
						name: 'Home'
					})
				}
			})
		}

		 const translate = (lang) => {
            store.commit('translate/setLang', lang)
        }

		onUpdated(() => {

            // ACTIVE ROUTE
            $('.sidebar-nav-v2 ul li a').parent().removeClass('page-arrow active-page')
            $('a.router-link-exact-active').parent().addClass('page-arrow active-page')

        })


		onMounted(() => {
            store.dispatch('currentUser/getProfile')
            setInterval(() => { store.dispatch('currentUser/getProfile') },8000)
        })


		return {
			t,
			translate,
			// DATA
			currentUser,
			authenticated,

			// METHODS
			logout
		}
	}
}
</script>

<style>
.app-container {
	width: 100%;
	height: 100vh;
}

/* CSS */
#sidebar-main  a.router-link-exact-active ,
#sidebar-main a.router-link-exact-active  i {
	color: #0088CC;
}

table tbody tr td {
    word-break: break-all !important;
}
.keep-all-text {
    word-break: keep-all !important;
}

</style>
